import {arrays, money, sorting} from "@atttomyx/shared-utils";
import {CHARGE_STATUS_FAILURE} from "../constants";

export const sortByNextBillDueAndReason = (a, b) => {
    return sorting.sortByFields(a, b, "nextBillDue", "reason");
};

export const calculateTotal = (charges, chargeIds) => {
    const included = charges
        .filter(charge => arrays.contains(chargeIds, charge.id))
        .map(charge => charge.money);

    return money.add(included);
};

export const isFailure = (charge) => charge.status === CHARGE_STATUS_FAILURE;
