import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Collapse, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {
    CheckBoxOutlined as OptionsIcon,
    ExpandLess,
    ExpandMore,
    Face as ProfileIcon,
    Fingerprint as PasswordIcon,
    Info as AboutIcon,
    Logout as LogoutIcon,
    SwapHoriz as SwitchIcon,
} from "@mui/icons-material";
import {confirm, mobile, router} from "@atttomyx/react-utils";
import {PAGE_ABOUT, PAGE_OPTIONS, PAGE_PASSWORD, PAGE_PROFILE} from "../../constants";

const MoreMenu = (props) => {
    const { history, billings, billingId, onCancel, onLogout, onSwitch } = props;
    const [showBillings, setShowBillings] = useState(false);

    const closeAndRedirect = (page) => {
        onCancel();
        router.redirectTo(history, page);
    };

    const closeAndSwitchAccounts = (billingId) => {
        onCancel();
        onSwitch(billingId);
    };

    const renderBillings = () => {
        return <>
            <ListItemButton onClick={() => setShowBillings(!showBillings)}>
                <ListItemIcon><SwitchIcon/></ListItemIcon>
                <ListItemText>Switch</ListItemText>
                {showBillings ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={showBillings} timeout="auto" unmountOnExit>
                <List disablePadding className="sub-menu">
                    {billings.filter(candidate => candidate.id !== billingId).map(billing =>
                        <ListItemButton
                            key={billing.id}
                            onClick={() => closeAndSwitchAccounts(billing.id)}
                        >
                            {billing.name}
                        </ListItemButton>)}
                </List>
            </Collapse>
        </>
    };

    return <Drawer anchor="right"
                   open={true}
                   onClose={onCancel}>
        <List>
            <ListItemButton onClick={() => closeAndRedirect(PAGE_PROFILE)}>
                <ListItemIcon><ProfileIcon/></ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => closeAndRedirect(PAGE_OPTIONS)}>
                <ListItemIcon><OptionsIcon/></ListItemIcon>
                <ListItemText>Options</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => closeAndRedirect(PAGE_PASSWORD)}>
                <ListItemIcon><PasswordIcon/></ListItemIcon>
                <ListItemText>Password</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => confirm.confirm(mobile.isMobile()
                ? "Someone would need to have physical access to your phone to access this account. Are you sure you want to logout?"
                : "Are you sure you want to logout?", onLogout)}>
                <ListItemIcon><LogoutIcon/></ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </ListItemButton>
            {billings.length > 1 ? renderBillings() : null}
            <Divider/>
            <ListItemButton onClick={() => closeAndRedirect(PAGE_ABOUT)}>
                <ListItemIcon><AboutIcon/></ListItemIcon>
                <ListItemText>About</ListItemText>
            </ListItemButton>
        </List>
    </Drawer>
};

export default withRouter(MoreMenu);
