import React from "react";
import {Card, CardActions, CardContent} from "@mui/material";
import {CardTitle} from "@atttomyx/react-components";

const BillingCard = (props) => {
    const { billing, children } = props;

    return <Card className="billing-card" raised={true}>
        <CardContent>
            <CardTitle>
                {billing.name}
            </CardTitle>
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default BillingCard;
