import {objects} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";

export const sanitizeProfile = (profile) => {
    profile.settings = sanitizeSettings(profile.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};

    sanitized.theme = objects.defaultIfNullOrUndefined(sanitized.theme, themes.LIGHT);

    return sanitized;
};
