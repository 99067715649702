import React, {useState} from 'react';
import {CircularProgress} from '@mui/material';
import CreditCardForm from "../../forms/creditCardForm/creditCardForm";
import * as billingService from "../../services/billing";
import {formatters} from "@atttomyx/shared-utils";
import {SQUARE_APP_ID, SQUARE_LOCATION_ID} from "../../constants";

const CreditCardPage = (props) => {
    const {snackbar, billingId, card, onSaveCard, onDeleteCard, onSavePayment} = props;
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const saveCard = (nonce) => {
        setSaving(true);

        const success = (card, payment) => {
            setSaving(false);

            snackbar.setSuccess(payment && payment.success
                ? "Paid " + formatters.usDollars(payment.money)
                : "Card saved");

            onSaveCard(card);

            if (payment) {
                onSavePayment(payment);
            }
        };

        const failure = (err) => {
            setSaving(false);

            snackbar.setError("Unable to save card");
        };

        billingService.saveCard(billingId, nonce, success, failure);
    };

    const deleteCard = () => {
        setDeleting(true);

        const success = () => {
            setDeleting(false);

            snackbar.setSuccess("Card deleted");
            onDeleteCard(card.id);
        };

        const failure = (err) => {
            setDeleting(false);

            snackbar.setError("Unable to delete card");
        };

        billingService.deleteCard(billingId, success, failure);
    };

    return <div className="credit-card-page">
        {saving || deleting ?
            <CircularProgress size="80px" color={deleting ? "secondary" : "primary"}/> : card ?
            <CreditCardForm
                appId={SQUARE_APP_ID}
                locationId={SQUARE_LOCATION_ID}
                data={card}
                onSave={saveCard}
                onDelete={deleteCard}
            /> : null}
    </div>
}

export default CreditCardPage;
