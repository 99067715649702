import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import PayNowForm from "../../forms/payNowForm/payNowForm";
import * as billingService from "../../services/billing";
import {formatters, forms} from "@atttomyx/shared-utils";
import {calculateTotal} from "../../utils/charges";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "400px",
    },
}));

const freshData = () => {
    return {
        chargeIds: [],
        valid: false,
    }
};

const PayNowDialog = (props) => {
    const { snackbar, billingId, charges, onCancel, onPaid } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (payment) => {
            snackbar.setSuccess("Paid " + formatters.usDollars(payment.money));
            setSaving(false);
            onPaid(payment);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        billingService.payNow(billingId, data.chargeIds, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="pay-now-dialog"
        classes={useStyles()}
    >
        <ClosableDialogTitle onClose={onCancel}/>
        <DialogContent className="pay-now-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <Typography paragraph={true}>
                        Which charges would you like to pay?
                    </Typography>
                    <PayNowForm
                        charges={charges}
                        data={data}
                        onChange={setData}
                    />
                    <Divider/>
                    <Typography>
                        Total: <strong>{formatters.usDollars(calculateTotal(charges, data.chargeIds))}</strong>
                    </Typography>
                </>}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Pay now
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default PayNowDialog;
