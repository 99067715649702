import React from "react";
import {milesoft} from "@atttomyx/shared-constants";
import {Divider, Typography} from "@mui/material";
import {ContactUs} from "@atttomyx/react-components";

const FreePage = () => {
    return <div className="free-page">
        <Typography variant="h5">
            Your {milesoft.COMPANY_NAME} experience is free!
        </Typography>
        <Typography variant="caption">
            You are not linked to any billing accounts.
        </Typography>
        <Divider/>
        <ContactUs showFeedback={false}/>
    </div>
}

export default FreePage;
