import React, {useState} from "react";
import {IconButton} from "@mui/material";
import {ReceiptLong as ReceiptIcon,} from "@mui/icons-material";
import {Cards, FloatingMoneyButton, TabBar, TabPanel} from "@atttomyx/react-components";
import {usePaging} from "@atttomyx/shared-hooks";
import ChargeCard from "../../cards/chargeCard/chargeCard";
import PaymentCard from "../../cards/paymentCard/paymentCard";
import PayNowDialog from "../../dialogs/payNowDialog/payNowDialog";
import {strings} from "@atttomyx/shared-utils";

const ChargesPage = (props) => {
    const {dimensions, snackbar, billingId, charges, payments, hasCard, onPaid} = props;
    const [tab, setTab] = useState(0);
    const [showPayNow, setShowPayNow] = useState(false);
    const chargePaging = usePaging();
    const paymentPaging = usePaging();

    return <div className="charges-page">
        <TabBar
            tabs={["Upcoming", "History"]}
            value={tab}
            onChange={setTab}
        />
        <TabPanel value={tab} index={0}>
            <Cards
                label="charge"
                items={charges}
                paging={chargePaging}
                dimensions={dimensions}
                renderer={(charge) =>
                    <ChargeCard key={charge.id} charge={charge}/>}
            />
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <Cards
                label="payment"
                items={payments}
                paging={paymentPaging}
                dimensions={dimensions}
                renderer={(payment) =>
                    <PaymentCard key={payment.id} payment={payment}>
                        <IconButton color="primary" title="Goto receipt"
                                    disabled={strings.isBlank(payment.receiptUrl)}
                                    onClick={() => window.open(payment.receiptUrl, "receipt")}>
                            <ReceiptIcon/>
                        </IconButton>
                    </PaymentCard>}
            />
        </TabPanel>
        {tab === 0 ?
            <FloatingMoneyButton title="Pay..." position="higher"
                                 disabled={charges.length === 0 || !hasCard}
                                 onClick={() => setShowPayNow(true)}
            /> : null}
        {showPayNow ?
            <PayNowDialog
                snackbar={snackbar}
                billingId={billingId}
                charges={charges}
                onCancel={() => setShowPayNow(false)}
                onPaid={(payment) => {
                    setShowPayNow(false);
                    onPaid(payment);
                }}
            /> : null}
    </div>
}

export default ChargesPage;
