import React from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime, formatters} from "@atttomyx/shared-utils";

const ChargeCard = (props) => {
    const { charge, children } = props;
    const gaveUp = charge.recurring && !charge.nextBillDue;

    return <Card className="charge-card" raised={true}>
        <CardContent>
            <CardTitle>
                {charge.reason}
            </CardTitle>
            {gaveUp ? <>
                <div>
                    <Typography variant="caption">
                        Max retries reached.
                    </Typography>
                </div>
                <Typography variant="caption" paragraph={true}>
                    Must be manually charged.
                </Typography>
            </> : null}
            <CardData
                data={charge}
                keyToMetadata={{
                    money: {
                        title: "Amount",
                        formatter: (money) => {
                            const rendered = formatters.usDollars(money);

                            return charge.recurring ? `${rendered} / month` : rendered;
                        },
                    },
                    nextBillDue: {
                        title: charge.recurring ? "Next Due" : "Due",
                        formatter: (data) => data ? datetime.getFullDate(data) : charge.immediate ? "Now" : "N/A",
                        hidden: gaveUp,
                    },
                    status: {
                        title: "Status",
                        className: "status " + charge.status,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default ChargeCard;
