import axios from 'axios';
import {arrays} from "@atttomyx/shared-utils";

export const listBillings = (cursor, limit, success, failure) => {
    const url = "/api/v1/billing/list";

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const findCharges = (billingId, success, failure) => {
    const url = `/api/v1/billing/${billingId}/charges`;

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json.charges);
        })
        .catch(err => {
            failure(err);
        });
};

export const findPayments = (billingId, success, failure) => {
    const url = `/api/v1/billing/${billingId}/payments`;

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json.payments);
        })
        .catch(err => {
            failure(err);
        });
};

export const findCards = (billingId, success, failure) => {
    const url = `/api/v1/billing/${billingId}/card`;

    axios.get(url)
        .then(response => {
            const json = response.data;
            const cards = [];

            if (json.card) {
                json.card.id = `${json.card.type}:${json.card.last4}`;

                arrays.addTo(cards, json.card);
            }

            success(cards);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveCard = (billingId, nonce, success, failure) => {
    const url = `/api/v1/billing/${billingId}/card`;

    axios.put(url, {
        nonce: nonce,
    })
        .then(response => {
            const json = response.data;

            success(json.card, json.payment);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteCard = (billingId, success, failure) => {
    const url = `/api/v1/billing/${billingId}/card`;

    axios.delete(url)
        .then(() => {
            success();
        })
        .catch(err => {
            failure(err);
        });
};

export const payNow = (billingId, chargeIds, success, failure) => {
    const url = `/api/v1/billing/${billingId}/chargeNow`;

    axios.post(url, {
        chargeIds: chargeIds,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};
