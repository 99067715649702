import {milesoft, notifications} from "@atttomyx/shared-constants";

export const APP_NAME = "Billing";
export const APP_TAG_LINE = `Manage your ${milesoft.COMPANY_NAME} account`;

// export const API_BASE_URL = "http://localhost:8080";
export const API_BASE_URL = "https://billing-app-2zjx2opnkq-uc.a.run.app";
export const WEB_BASE_URL = "https://billing.milesoft.app";

export const SQUARE_APP_ID = "sq0idp-7wq95robgy0vxukl4kSk1A";
export const SQUARE_LOCATION_ID = "L7EXVJARGCY8Q";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const NAMESPACE = "billing";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_TEMPORARY_PASSWORD = "temp_code";

export const CHARGE_STATUS_PENDING = "Pending";
export const CHARGE_STATUS_SUCCESS = "Success";
export const CHARGE_STATUS_FAILURE = "Failure";

export const MODE_FREE = "free";
export const MODE_SINGLE = "single";
export const MODE_MULTIPLE = "multiple";

export const NOTIFICATION_TYPES = {
    charge_added: {
        short: "Charge Added",
        long: "A charge was added to your account",
        delivery: notifications.DELIVERY_EMAIL,
    },
    charge_deleted: {
        short: "Charge Removed",
        long: "A charge was removed from your account",
        delivery: notifications.DELIVERY_EMAIL,
    },
    charge_upcoming: {
        short: "Upcoming Charge",
        long: "A week prior to an automatic charge",
        delivery: notifications.DELIVERY_EMAIL,
    },
    payment_success: {
        short: "Successful Payment",
        long: "A payment was successful",
        delivery: notifications.DELIVERY_EMAIL,
    },
    payment_failure: {
        short: "Failed Payment",
        long: "A payment was unable to go through",
        delivery: notifications.DELIVERY_EMAIL,
    },
};

export const TOPICS = {};

export const PAGE_LOGIN = "/login";
export const PAGE_RECOVERY = "/recovery";
export const PAGE_WELCOME = "/welcome";

export const PAGE_CHARGES = "/charges";
export const PAGE_CARD = "/card";
export const PAGE_NOTIFICATIONS = "/notifications";

export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_PASSWORD = "/password";
export const PAGE_ABOUT = "/about";

export const PAGE_HOME = "/home";

export const PAGES_MAIN = [
    PAGE_CHARGES,
    PAGE_CARD,
    PAGE_NOTIFICATIONS,
];

export const PAGES_MORE = [
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_ABOUT,
];

export const PRIVATE_PAGES_EXACT = [].concat(PAGES_MAIN).concat(PAGES_MORE);

export const PRIVATE_PAGES_STARTS_WITH = [];

export const PUBLIC_PAGES_EXACT = [
    PAGE_LOGIN,
    PAGE_RECOVERY,
    PAGE_WELCOME,
];

export const PUBLIC_PAGES_STARTS_WITH = [];
