import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {useBootstrap, useCachedByOwnerId, useEntities, useProgress} from "@atttomyx/shared-hooks";
import {
    AboutPage,
    ChangePasswordPage,
    ForgotPasswordPage,
    LoginPage,
    NotFoundPage,
    NotificationsPage,
    ProfilePage,
    PublicHeader,
    Splash,
    UserSettingsPage,
    WelcomePage
} from "@atttomyx/react-components";
import Footer from "./components/footer/footer";
import UserSettingsForm from "./forms/userSettingsForm/userSettingsForm";
import ChooseBillingAccountPage from "./pages/chooseBillingAccountPage/chooseBillingAccountPage";
import FreePage from "./pages/freePage/freePage";
import ChargesPage from "./pages/chargesPage/chargesPage";
import CreditCardPage from "./pages/creditCardPage/creditCardPage";
import * as appService from "./services/app";
import * as authService from "./services/auth";
import * as billingService from "./services/billing";
import * as myAccountService from "./services/noopMyAccount";
import * as notificationService from "./services/notifications";
import * as profileService from "./services/profile";
import {arrays, notifications as notificationUtils, sorting, strings} from "@atttomyx/shared-utils";
import {branding as brandingUtils, router} from "@atttomyx/react-utils";
import {isFailure, sortByNextBillDueAndReason} from "./utils/charges";
import {hasCard} from "./utils/cards";
import {themes, time} from "@atttomyx/shared-constants";
import {
    APP_NAME,
    APP_TAG_LINE,
    MODE_FREE,
    MODE_MULTIPLE,
    MODE_SINGLE,
    NOTIFICATION_TYPES,
    PAGE_ABOUT,
    PAGE_CARD,
    PAGE_CHARGES,
    PAGE_HOME,
    PAGE_LOGIN,
    PAGE_NOTIFICATIONS,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_RECOVERY,
    PAGE_WELCOME,
    PRIVATE_PAGES_EXACT,
    PRIVATE_PAGES_STARTS_WITH,
    PUBLIC_PAGES_EXACT,
    PUBLIC_PAGES_STARTS_WITH,
    TOPICS,
} from "./constants";
import icon from "./icon.png";

const App = props => {
    const {dimensions, snackbar, history, onThemeChange} = props;
    const [mode, setMode] = useState(null);
    const [billingId, setBillingId] = useState(null);
    const [charges, setCharges] = useState([]);
    const [payments, setPayments] = useState([]);
    const [card, setCard] = useState({});
    const notifications = useEntities(notificationService.listNotifications, snackbar.setError, "notifications", sorting.sortByCreatedDesc);
    const billings = useEntities(billingService.listBillings, snackbar.setError, "billings", sorting.sortByName);
    const chargeCache = useCachedByOwnerId(billingService.findCharges, snackbar.setError, "charges", sortByNextBillDueAndReason);
    const paymentCache = useCachedByOwnerId(billingService.findPayments, snackbar.setError, "payments", sorting.sortByCreatedDesc);
    const cardCache = useCachedByOwnerId(billingService.findCards, snackbar.setError, "cards", sorting.sortByCreatedDesc);

    const onLogout = () => {
        authService.cancelAllRequests("logged out");
        authService.clearAuthToken();

        progress.clear();
        bootstrap.clear();
        notifications.clear();
        billings.clear();
        chargeCache.clear();
        paymentCache.clear();
        cardCache.clear();

        router.redirectToInitialPage(history, PUBLIC_PAGES_EXACT, PUBLIC_PAGES_STARTS_WITH, PAGE_LOGIN);
    };

    const bootstrapSuccess = () => {
        const temporaryPassword = authService.getTemporaryPassword();

        if (strings.isNotBlank(temporaryPassword)) {
            router.redirectTo(history, PAGE_PASSWORD);

        } else {
            router.redirectToInitialPage(history, PRIVATE_PAGES_EXACT, PRIVATE_PAGES_STARTS_WITH, PAGE_HOME);
        }

        notifications.refresh();
        billings.refresh();
    };

    const bootstrapFailure = (err) => {
        snackbar.setError(err);
        onLogout();
    };

    const bootstrap = useBootstrap(authService, myAccountService, profileService, notificationService,
        bootstrapSuccess, bootstrapFailure);
    const progress = useProgress(bootstrap, [notifications, billings], [],
        time.MILLISECONDS_IN_SECOND);

    const onLogin = (accounts) => {
        progress.refresh();
        bootstrap.refresh(accounts);
    };

    useEffect(() => {
        const settings = bootstrap.user ? bootstrap.user.settings || {} : {};
        const theme = settings.theme || themes.LIGHT;
        const branding = brandingUtils.getBranding(theme);

        onThemeChange(theme, branding);
    }, [bootstrap.user]);

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(onLogout);
        notificationUtils.initNotifications(NOTIFICATION_TYPES, TOPICS);

        if (authService.loggedIn()) {
            onLogin();

        } else {
            onLogout();
        }
    }, []);

    useEffect(() => {
        if (billings.entities.length > 1) {
            setMode(MODE_MULTIPLE);

        } else if (billings.entities.length > 0) {
            setMode(MODE_SINGLE);
            setBillingId(billings.entities[0].id);

        } else {
            setMode(MODE_FREE);
        }
    }, [billings.entities]);

    useEffect(() => {
        if (billingId) {
            chargeCache.get(billingId, (charges) => setCharges(arrays.copy(charges)), false);
            paymentCache.get(billingId, (payments) => setPayments(arrays.copy(payments)), false);
            cardCache.get(billingId, (cards) => setCard(cards.length > 0 ? cards[0] : {}), false);
        }
    }, [billingId]);

    return <div className="app">
        {progress.loading || progress.paused ?
            <Splash
                percent={progress.percent}
            /> : bootstrap.user ? chargeCache.loading || paymentCache.loading ?
            <div className="content">
                <CircularProgress color="primary" size="40px"/>
            </div> : billings.entities.length > 1 && !billingId ?
            <div className="content">
                <ChooseBillingAccountPage
                    billings={billings.entities}
                    onChoose={setBillingId}
                    onBackToLogin={onLogout}
                />
            </div> :
            <>
                <div className="content">
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={PAGE_CHARGES}/>
                        </Route>
                        <Route exact path={PAGE_HOME}>
                            <Redirect to={PAGE_CHARGES}/>
                        </Route>
                        <Route path={PAGE_CHARGES}>
                            {mode === MODE_FREE ?
                            <FreePage/> :
                            <ChargesPage
                                dimensions={dimensions}
                                snackbar={snackbar}
                                billingId={billingId}
                                charges={charges}
                                payments={payments}
                                hasCard={hasCard(card)}
                                onPaid={(payment) => {
                                    chargeCache.get(billingId, setCharges, true);
                                    paymentCache.get(billingId, setPayments, true);
                                }}
                            />}
                        </Route>
                        {mode !== MODE_FREE ?
                            <Route path={PAGE_CARD}>
                                <CreditCardPage
                                    snackbar={snackbar}
                                    billingId={billingId}
                                    card={card}
                                    onSaveCard={(card) => {
                                        cardCache.onEntitySaved(billingId, card);
                                        setCard(card);
                                    }}
                                    onDeleteCard={(cardId) => {
                                        cardCache.onEntityDeleted(billingId, cardId);
                                        setCard({});
                                    }}
                                    onSavePayment={(payment) => {
                                        chargeCache.get(billingId, setCharges, true);
                                        paymentCache.get(billingId, setPayments, true);
                                    }}
                                />
                            </Route> : null}
                        <Route path={PAGE_NOTIFICATIONS}>
                            <NotificationsPage
                                dimensions={dimensions}
                                snackbar={snackbar}
                                notifications={notifications.entities}
                                onBulkSave={notifications.onEntitiesSaved}
                                onDelete={notifications.onEntityDeleted}
                                notificationService={notificationService}
                            />
                        </Route>
                        <Route path={PAGE_PROFILE}>
                            <ProfilePage
                                snackbar={snackbar}
                                user={bootstrap.user}
                                onSave={bootstrap.syncProfile}
                                profileService={profileService}
                                showImage={true}
                            />
                        </Route>
                        <Route path={PAGE_OPTIONS}>
                            <UserSettingsPage
                                snackbar={snackbar}
                                user={bootstrap.user}
                                onSaveProfile={bootstrap.syncProfile}
                                onSaveNotifications={bootstrap.syncNotifications}
                                profileService={profileService}
                                notificationService={notificationService}
                                settingsForm={UserSettingsForm}
                                allowApp={true}
                                allowEmail={true}
                            />
                        </Route>
                        <Route path={PAGE_PASSWORD}>
                            <ChangePasswordPage
                                snackbar={snackbar}
                                authService={authService}
                                onChanged={() => router.redirectTo(history, PAGE_HOME)}
                            />
                        </Route>
                        <Route path={PAGE_ABOUT}>
                            <AboutPage
                                src={icon}
                                appName={APP_NAME}
                                appDescription={APP_TAG_LINE}
                                appService={appService}
                                user={bootstrap.user}
                            />
                        </Route>
                        <Route>
                            <NotFoundPage/>
                        </Route>
                    </Switch>
                </div>
                <Footer
                    mode={mode}
                    billings={billings.entities}
                    billingId={billingId}
                    numNotifications={notifications.entities.filter(notificationUtils.isNewNotification).length}
                    numFailedCharges={charges.filter(isFailure).length}
                    hasCard={hasCard(card)}
                    onLogout={onLogout}
                    onSwitch={setBillingId}
                />
            </> :
            <>
                <PublicHeader appName={APP_NAME} src={icon}/>
                <div className="content">
                    <Switch>
                        <Route path={PAGE_LOGIN}>
                            <LoginPage
                                snackbar={snackbar}
                                authService={authService}
                                onForgotPassword={() => router.redirectTo(history, PAGE_RECOVERY)}
                                onLogin={onLogin}
                            />
                        </Route>
                        <Route path={PAGE_RECOVERY}>
                            <ForgotPasswordPage
                                snackbar={snackbar}
                                authService={authService}
                                onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                                appName={APP_NAME}
                            />
                        </Route>
                        <Route path={PAGE_WELCOME}>
                            <WelcomePage
                                snackbar={snackbar}
                                authService={authService}
                                onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                            />
                        </Route>
                    </Switch>
                </div>
            </>}
    </div>
}

export default withRouter(App);
