import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {AppBar, Badge, BottomNavigation, BottomNavigationAction} from "@mui/material";
import {
    CreditCard as CardIcon,
    MoreHoriz as MoreIcon,
    Notifications as NotificationsIcon,
    Receipt as ChargesIcon
} from "@mui/icons-material";
import MoreMenu from "../moreMenu/moreMenu";
import {arrays} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {MODE_FREE, PAGE_CARD, PAGE_CHARGES, PAGE_NOTIFICATIONS, PAGES_MAIN, PAGES_MORE} from "../../constants";
import "./footer.css";

const PAGE_MORE = "more";
const PAGE_OTHER = "other";

const Footer = (props) => {
    const {history, mode, billings, billingId, numNotifications, numFailedCharges, hasCard,
        onLogout, onSwitch} = props;
    const [showMore, setShowMore] = useState(false);
    const disabled = mode === MODE_FREE;

    const path = router.getCurrentPage(history);
    let page = PAGE_OTHER;

    if (arrays.contains(PAGES_MAIN, path)) {
        page = path;

    } else if (arrays.contains(PAGES_MORE, path)) {
        page = PAGE_MORE;
    }

    return <div className="footer">
        <AppBar className="bottom-nav" position="fixed" color="default">
            <BottomNavigation
                value={page}
                showLabels={true}
            >
                <BottomNavigationAction
                    value={PAGE_CHARGES}
                    label="Charges"
                    icon={<Badge color="error"
                                 badgeContent={numFailedCharges}
                                 invisible={disabled || numFailedCharges === 0}>
                        <ChargesIcon/>
                    </Badge>}
                    onClick={() => router.redirectTo(history, PAGE_CHARGES)}
                />
                <BottomNavigationAction
                    value={PAGE_CARD}
                    label="Card"
                    icon={<Badge color="error"
                                 badgeContent="!"
                                 invisible={disabled || hasCard}>
                        <CardIcon/>
                    </Badge>}
                    disabled={disabled}
                    onClick={() => router.redirectTo(history, PAGE_CARD)}
                />
                <BottomNavigationAction
                    value={PAGE_NOTIFICATIONS}
                    label="Notifications"
                    icon={
                        <Badge
                            color="error"
                            badgeContent={numNotifications}
                            invisible={numNotifications === 0}>
                            <NotificationsIcon/>
                        </Badge>}
                    onClick={() => router.redirectTo(history, PAGE_NOTIFICATIONS)}
                />
                <BottomNavigationAction value={PAGE_MORE}
                                        label="More"
                                        icon={<MoreIcon/>}
                                        component="div"
                                        onClick={() => setShowMore(true)}
                />
            </BottomNavigation>
        </AppBar>
        {showMore ?
            <MoreMenu
                billings={billings}
                billingId={billingId}
                onCancel={() => setShowMore(false)}
                onLogout={onLogout}
                onSwitch={onSwitch}
            /> : null}
    </div>
}

export default withRouter(Footer);
