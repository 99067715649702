import React, {useEffect, useState} from 'react';
import {CreditCard, PaymentForm} from 'react-square-web-payments-sdk';
import {useTheme} from "@mui/styles";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {IconButton, TextField} from "@mui/material";
import {strings} from "@atttomyx/shared-utils";
import {confirm} from "@atttomyx/react-utils";
import "./creditCardForm.css";

const CreditCardForm = (props) => {
    const {appId, locationId, data, onSave, onDelete} = props;
    const [editing, setEditing] = useState(strings.isBlank(data.last4));
    const theme = useTheme();

    useEffect(() => {
        setEditing(strings.isBlank(data.last4));
    }, [data]);

    return <div className="credit-card-form">
        {editing ?
            <PaymentForm
                applicationId={appId}
                locationId={locationId}
                cardTokenizeResponseReceived={(token, buyer) => {
                    onSave(token.token);
                }}
            >
                <CreditCard
                    render={(Button) =>
                        <>
                            {strings.isNotBlank(data.last4) ?
                                <a style={{
                                    color: theme.palette.secondary.main,
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                    display: "inline-block",
                                    width: "100px",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                }} onClick={() => setEditing(false)}>Undo</a> : null}
                            <Button css={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                display: "inline-block",
                                width: "100px",
                                marginLeft: "10px",
                                marginRight: "10px",
                            }}>Save</Button>
                        </>}
                />
            </PaymentForm> :
            <>
                <div className="field">
                    <TextField
                        type="text"
                        label="Card"
                        disabled={true}
                        value={data.type + " ************" + data.last4}
                    />
                </div>
                <div className="actions">
                    <IconButton color="secondary" title="Delete"
                                onClick={() => confirm.confirmDelete("this card", onDelete)}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => setEditing(true)}>
                        <EditIcon/>
                    </IconButton>
                </div>
            </>}
    </div>
};

export default CreditCardForm;
