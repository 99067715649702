import React from "react";
import {Forward as LoginIcon} from "@mui/icons-material";
import {Button, IconButton, Typography} from "@mui/material";
import BillingCard from "../../cards/billingCard/billingCard";

const ChooseBillingAccountPage = (props) => {
    const { billings, onChoose, onBackToLogin } = props;

    return <div className="choose-billing-account">
        <Typography variant="h5" paragraph={true}>
            Choose billing account
        </Typography>
        <div className="cards">
            {billings.map(billing => <BillingCard key={billing.id}
                                                  billing={billing}>
                <IconButton color="primary" title="Login to this account"
                            onClick={() => onChoose(billing.id)}>
                    <LoginIcon/>
                </IconButton>
            </BillingCard>)}
        </div>
        <div className="action">
            <Button color="secondary" variant="text"
                    onClick={onBackToLogin}>
                Back to login
            </Button>
        </div>
    </div>
};

export default ChooseBillingAccountPage;
