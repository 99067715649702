export const loadMyAccount = (success, failure) => {
    success({});
};

export const saveMyAccount = (account, success, failure) => {
    success({});
};

export const removeCard = (success, failure) => {
    success();
};

export const chargeNow = (success, failure) => {
    success({});
};

export const uploadImage = (accountId, logo, success, failure) => {
    success(null);
};
