import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {arrays, formatters, objects} from "@atttomyx/shared-utils";

const PayNowForm = (props) => {
    const {charges, data, onChange} = props;

    const syncChargeIds = (chargeId, checked) => {
        const modified = objects.deepCopy(data);
        const chargeIds = [...modified.chargeIds];

        if (arrays.contains(chargeIds, chargeId)) {
            if (!checked) {
                arrays.removeFrom(chargeIds, chargeId);
            }

        } else if (checked) {
            arrays.addTo(chargeIds, chargeId);
        }

        modified.chargeIds = chargeIds;
        modified.valid = chargeIds.length > 0;

        onChange(modified);
    }

    return <div className="pay-now-form">
        {charges.map(charge => <div key={charge.id} className="option">
            <FormControlLabel
                labelPlacement="end"
                label={`${formatters.usDollars(charge.money)} - ${charge.reason}`}
                control={<Checkbox
                    color="primary"
                    checked={arrays.contains(data.chargeIds, charge.id)}
                    onChange={(event) => syncChargeIds(charge.id, event.target.checked)}
                />}
            />
        </div>)}
    </div>
}

export default PayNowForm;
